@import "styles/theme.scss";
%full-height {
  height: 100%;
}

.background {
  @extend %full-height;

  background: var(--main-gradient);
  position: relative;
  overflow: hidden;
  display: grid;
  place-items: center;
  isolation: isolate;

  @media (max-height: 620px) {
    overflow-y: auto;

    &::before {
      content: revert;
    }
  }

  &::before {
    --dimension: calc(100% - 152px);

    content: '';
    position: relative;
    grid-area: 1/1;
    top: -6.77%;
    border-radius: 50%;
    border: 1px solid #fff;
    z-index: -1;

    @supports (aspect-ratio: 1/1) {
      width: var(--dimension);
      aspect-ratio: 1/1;
    }
  }
}

.wrapper {
  @extend %full-height;

  grid-area: 1/1;
}

.form {
  @extend %full-height;

  padding-top: 214px;
  max-height: 668px;
  max-width: 264px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.elevate {
  z-index: 1;
  width: 100%;
}

.button {
  margin-top: auto;
}

.buttonGroup + .buttonGroup {
  margin-top: 24px;
}

.img {
  margin-bottom: 44px;
}

.text {
  margin-bottom: 120px;
  font-size: $font-size-xs;
  font-weight: bold;
  line-height: 1.5;
  text-transform: uppercase;
  text-align: center;
  color: $grey-light-color;
}

.error {
  position: relative;
  top: -10px;
  padding: 15px 18px 6px;
  overflow: hidden;
  border-radius: 0 0 10px 10px;
  font-size: $font-size-xxs;
  background-color: $wrong-color;
  color: #fff;
  z-index: -1;

  * {
    color: inherit;
  }
}
